<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">발신프로필 등록</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">발신프로필 등록</h3>
            <div class="float-right">
              <base-button type="primary" block @click="register">등록</base-button>
            </div>
          </template>
          <template>
            <form role="form" class="needs-validation">
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">채널 아이디 <span class="text-danger">*</span></label>
                <div class="col-md-10 ">
                  <base-input>
                    <base-input v-model="model.yellowId"
                                name="yellowId"
                                :error="_getError('yellowId')"
                                :maxlength="25"
                                v-validate="'required'"
                                placeholder="@UUID">
                    </base-input>
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">카테고리 <span class="text-danger">*</span></label>
                <div class="col-md-3">
                  <base-input v-model="category1Code"
                              name="category1Code"
                              :error="_getError('category1Code')"
                              :maxlength="1"
                              v-validate="'required'">
                    <el-select v-model="category1Code"
                               filterable
                               @change="changeSelect(1);"
                               placeholder="대분류">
                      <el-option v-for="option in category1DethList"
                                 :key="option.code"
                                 :label="option.name"
                                 :value="option.code">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-3">
                  <base-input v-model="category2Code"
                              name="category2Code"
                              :error="_getError('category2Code')"
                              :maxlength="1"
                              v-validate="'required'">
                    <el-select v-model="category2Code"
                               filterable
                               @change="changeSelect(2);"
                               placeholder="중분류">
                      <el-option v-for="option in category2DethList"
                                 :key="option.code"
                                 :label="option.name"
                                 :value="option.code">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-md-4">
                  <base-input v-model="category3Code"
                              name="category3Code"
                              :error="_getError('category3Code')"
                              :maxlength="1"
                              v-validate="'required'">
                    <el-select v-model="category3Code"
                               filterable
                               placeholder="소분류">
                      <el-option v-for="option in category3DethList"
                                 :key="option.code"
                                 :label="option.name"
                                 :value="option.code">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">채널 관리자 휴대폰번호 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input group class="mb-3">
                    <input type="text" class="form-control"
                           v-model="model.phoneNumber"
                           name="phoneNumber"
                           :maxlength="13"
                           @keyup="phoneMask"
                           v-validate="'required'"
                           :error="_getError('phoneNumber')"
                           placeholder="휴대폰번호">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="sendToken()">인증 번호 발송</button>
                    </div>
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">인증번호<span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input v-model="model.token"
                              name="token"
                              :maxlength="10"
                              v-validate="'required'"
                              :error="_getError('token')"
                              placeholder="인증번호">
                  </base-input>
                </div>
              </div>
            </form>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
  import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE, SITE_CODE } from '@/shared/util/const';
  import swal from "sweetalert2";

  export default {
    inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
    name: 'ProfileRegister',
    mixins: [ commJsUtilMixin, commValidatorMixin ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
    },
    data() {
      return {
        category1Code : null,
        category2Code : null,
        category3Code : null,
        categoryList : [],
        category1DethList : [],
        category2DethList : [],
        category3DethList : [],
        tokenStatus : 'N',  // 발송전 : N , 발송완료  :Y
        model: {
          yellowId : null,
          categoryCode : null,
          channelKey   : null,
          phoneNumber  : null
        }
      }
    },
    created() {
      this.getCategoryList();
    },
    methods: {
      /**
       * 카테고리 목록 조회
       */
      getCategoryList() {
        this.$axios.get(`${USER_API_PREFIX}/sender/category-list/list`, {
        }).then(res=> {
          if(res.data.result.code === RESULT_CODE.SUCCESS) {
            this.categoryList = res.data.result.categoryList;
            let category1Deth = [];
            let category2Deth = [];
            let category3Deth = [];
            for(let i=0, length = this.categoryList.length; i < length; i++) {
              let nameArray = this.categoryList[i].name.split(',');

              if(i == 0){
                this.category1Code = this.categoryList[i].code.substring(0,3);
                this.category2Code = this.categoryList[i].code.substring(3,7);
                this.category3Code = this.categoryList[i].code.substring(7);
              }

              let depth1 = {
                'code' : this.categoryList[i].code.substring(0,3),
                'name' : nameArray[0]
              }
              category1Deth.push(depth1);

              if(this.categoryList[i].code.substring(0,3) == this.category1Code) {
                let depth2 = {
                  'code': this.categoryList[i].code.substring(3, 7),
                  'name': nameArray[1]
                }
                category2Deth.push(depth2);

                if(this.categoryList[i].code.substring(3,7) == this.category2Code) {
                  let depth3 = {
                    'code': this.categoryList[i].code.substring(7),
                    'name': nameArray[2]
                  }
                  category3Deth.push(depth3);
                }
              }
            }

            this.category1DethList = category1Deth.filter((thing, index) => {
              const _thing = JSON.stringify(thing);
              return index === category1Deth.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
              });
            });

            this.category2DethList = category2Deth.filter((thing, index) => {
              const _thing = JSON.stringify(thing);
              return index === category2Deth.findIndex(obj => {
                return JSON.stringify(obj) === _thing;
              });
            });

            this.category3DethList = category3Deth;

          }
        }).catch((error) => {
          console.log(error)
        });
      },
      /**
       * 카테고리 변경 이벤트
       */
      changeSelect(depth){
        if(depth == 1){
          this.category2Code = '0001';
          this.category3Code = '0001';
        }else if(depth == 2){
          this.category3Code = '0001';
        }
        let category2Deth = [];
        let category3Deth = [];
        for(let i=0, length = this.categoryList.length; i < length; i++) {
          let nameArray = this.categoryList[i].name.split(',');

          if(this.categoryList[i].code.substring(0,3) == this.category1Code) {
            let depth2 = {
              'code': this.categoryList[i].code.substring(3, 7),
              'name': nameArray[1]
            }
            category2Deth.push(depth2);

            if(this.categoryList[i].code.substring(3,7) == this.category2Code) {
              let depth3 = {
                'code': this.categoryList[i].code.substring(7),
                'name': nameArray[2]
              }
              category3Deth.push(depth3);
            }
          }
        }
        this.category2DethList = category2Deth.filter((thing, index) => {
          const _thing = JSON.stringify(thing);
          return index === category2Deth.findIndex(obj => {
            return JSON.stringify(obj) === _thing;
          });
        });

        this.category3DethList = category3Deth;
      },
      /**
       * 토큰 발행
       */
      sendToken(){
        let chk = true;
        if(this.checkNull(this.model.yellowId)) {
          this.alertMsg('메시지유형을 선택해 주세요.')
          chk = false;
          return false;
        }
        if(this.checkNull(this.model.phoneNumber)) {
          this.alertMsg('수신번호를 입력해 주세요.')
          chk = false;
          return false;
        }
        if(chk) {
          this.$axios.post(`${USER_API_PREFIX}/sender/token`, {
            yellowId: this.model.yellowId,
            phoneNumber: this.model.phoneNumber
          }).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.notify('default', res.data.result.message);
              this.tokenStatus = 'Y';
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        }
      },
      /**
       * 발신프로필 등록 실행
       */
      register() {
        let chk = true;
        if(this.tokenStatus != 'Y'){
          this.alertMsg('휴대폰 인증을 진행해 주세요.')
          chk = false;
          return false;
        }
        if(chk){
          this._swalQuestion({ title:'등록 하시겠습니까?' }, () => {
            this.model.categoryCode = this.category1Code + this.category2Code + this.category3Code;
            this.$axios.post(`${USER_API_PREFIX}/sender/create`, {
              yellowId      : this.model.yellowId,
              categoryCode  : this.model.categoryCode,
              channelKey    : this.model.channelKey,
              phoneNumber   : this.model.phoneNumber,
              token         : this.model.token
            }).then(res => {
              if (res.data.result.code === RESULT_CODE.SUCCESS) {
                this.notify('default', res.data.result.message);
                this.$router.push('profile-list');
              } else {
                this.notify('warning', res.data.result.message);
              }
            });
          });
        }
      },
      /**
       * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
       */
      phoneMask() {
        this.model.phoneNumber = this._autoHyphenPhoneNumber(this.model.phoneNumber);
      },

      /**
       * 등록 실행 전, validate check!
       */
      processRegister() {
        this._validateBeforeSubmit(this.register);
      },
      /**
       * null check
       */
      checkNull(param) {
        if (param === undefined || param === null || '' == param) {
          return true;
        } else {
          return false;
        }
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },
    }
  };
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
